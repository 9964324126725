import React from "react";
import SEO from "../../components/seo";
import useProfilePic from "../../hooks/useProfilePic";
import ProfilePage from "../../layouts/ProfilePage";

const Nabil = () => {
	const { nabil: pic } = useProfilePic();
	return (
		<>
			<SEO
				title="Our Team of Specialists"
				keywords={[`Gastroenterologist`, `Dr Nabil Siddique`]}
				description="About Dr Nabil Siddique"
			/>
			<ProfilePage
				title="Dr Nabil Siddique - Gastroenterologist"
				name="Dr Nabil Siddique"
				img={pic.childImageSharp.fixed}
				quote=""
				specialty="All aspects of luminal gastroenterology including inflammatory bowel diseases, general gastroenterology and nutrition"
				education={[
					"Undergraduate: St George’s Hospital Medical School, University of London",
					"Postgraduate GI Training: King’s College Hospital, London",
					"Postgraduate GI Training: Royal Brighton and Sussex Hospital",
					"Fellowship: Fiona Stanley Hospital, Perth, Western Australia",
				]}
				description={[
					"Dr Siddique is a UK trained gastroenterologist who graduated from the University of London. He undertook higher specialist gastroenterology training in South London and tertiary hospital placements at King’s College Hospital, London and the Royal Brighton and Sussex Hospital.",
					"He  undertook a fellowship at Fiona Stanley Hospital before becoming a consultant at University Hospital Wales (UHW), in Cardiff, UK, in 2018. He became joint Clinical lead for Inflammatory Bowel Diseases (IBD) at UHW which has one of the largest IBD cohorts in the UK. Among other projects, he developed the IBD and intestinal failure service and led the implementation of an online patient portal to empower patients in the management of their IBD. In 2020 he moved to the Prince Charles Hospital, Brisbane, Australia where was Clinical Lead for IBD and Safety & Quality.",
					"He has extensive expertise in all aspects of luminal gastroenterology including inflammatory bowel diseases, general gastroenterology and nutrition.",
					"He provides high quality endoscopic procedures for both gastroscopy and colonoscopy and has specialist training in Barrett’s oesophagus, gastric metaplasia and IBD dysplasia surveillance. He regularly audits his endoscopic practice to ensure that high standards are maintained.",
				]}
				honours={[
					"",
				]}
				background="Nabil graduated from St George’s Hospital Medical School, University of London. He passed his Royal College of Physicians Specialty Certificate Examination in Gastroenterology in 2013 and was awarded his Certificate of Completion of Training in 2016. He held consultant positions at University Hospital Wales, UK and The Prince Charles Hospital, Brisbane before he moved to his current post as IBD and luminal gastroenterology consultant at the Sir Charles Gairdner Hospital in 2022."
				appointments={[
					"Private appointment - Hollywood Private Hospital, Perth",
					"Public appointment - Sir Charles Gairdner Hospital, Perth",
				]}
				memberships={[
					"Royal College of Physicians",
					"The Royal Australasian College of Physicians (RACP)",
					"Australian Health Practitioner Regulatory Authority (AHPRA)",
					"European Crohn’s and Colitis Organisation",
					"British Society of Gastroenterology",
					"British Association of Parenteral and Enteral Nutrition",
					"European Society for Clinical Nutrition and Metabolism",
				]}
				personal={[
					"I enjoy running, cycling and martial arts. Being new to Perth I like cycling around the beautiful  coastline with my family. I also like to dabble in the culinary arts, adding my own twist to traditional dishes.",
				]}
				publications={[
					{
						publicationTitle: "Audit of Steroid Excess in the Era of Biologics",
						authors: "Parry R, Durai D, Hawthorne B,  Siddique N, Evans R, Datta K.",
					},
					{
						publicationTitle: "Do Gastroenterologists Monitor their Patients Taking 5-Aminosalicylates Following Initiation of Treatment?",
						authors: "Siddique N, Farmer C,Irving J,Muller A.",
					},
					{
						publicationTitle: "Faecal calprotectin identifies non-responders to anti-TNFa therapy when measured after induction in inflammatory Crohn’s disease",
						authors: "Pavlidis P, Cavazza A, Siddique N, Stamoulos P, Tumova J, Medcalf L, Chung-Faye G, Dubois P, Bjarnason I, Hayee B",
					},
					{
						publicationTitle: "A Dedicated Barrett’s Oesophagus Endoscopy List improves the accuracy of endoscopic recording and dysplasia detection",
						authors: "Al-Hasani H, Mi Ha S, Matsuka L, Sharp T, Siddique N",
					},
					{
						publicationTitle: "Decreasing Incidence of AA Amyloidosis in Spain",
						authors: "Siddique N, Gillmore JD, Sattianayagam PT",
					},
					{
						publicationTitle: "Spontaneous Bacterial Peritonitis: Are We Following Guidelines?",
						authors: "Mi Ha S, Al-Hasani H, Crisan L, Siddique N",
					},
				]}
			/>
		</>
	);
};

export default Nabil;
